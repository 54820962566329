import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
  <>
  <header style={{background: `#01264f`}}>
    <div
      style={{
        margin: `0`,
        padding: `1em`,
        background: `#01264f`
      }}
    >
      <h1 style={{ margin: '0', paddingLeft:'0.8em', color:`#fff`, fontFamily:`Rockwell`, fontWeight:`600`, fontSize:`18px` }}>
        <Link
          to="/"
          style={{
            color: `#fff`,
            textDecoration: `none`,
          }}
        >
          Leah Singh, M.S, CCC-SLP
        </Link>
      </h1>
      <p style={{ margin: '0', paddingLeft:'1em', color:`#fff`, fontFamily:`Avenir Next Condensed`, fontWeight:`500`, fontStyle:`italic`, fontSize:`14px`, paddingTop:`0`}}>Speech-Language Pathologist</p>
    </div>
    </header>
   </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Singh Speech and Language`,
}

export default Header
